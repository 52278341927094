<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo />
      <h2 class="brand-text text-primary ml-1"></h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class=""
          variant="primary"
          label="Loading"
        />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BSpinner } from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
// import store from "@/store/index";
import { appload } from "@/libs/appinits";

export default {
  components: {
    VuexyLogo,
    BSpinner,
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {
    appload(this.$store, this.$ability)
      .then((resp) => {
        console.log("appload success", resp);
        this.$router.push({ path: "/" });
      })
      .catch((error) => {
        console.log("appload fail: " + error.message);
        this.$router.push({ name: "auth-login" });
      });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
